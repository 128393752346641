import React from "react";
import { connect } from "react-redux";

import { CYBER_APP_ID } from "../../../config";
import PortaleChildApp from "../../portale-child-app";

const CyberView = ({ workspaceId }) => {
    return (
        <PortaleChildApp
            appId="CYBER"
            serviceId="placeholder"
            deepLinkParameters={{
                tenantId: workspaceId,
                workspaceId: workspaceId,
                appId: CYBER_APP_ID
            }}
        />
    );
};

const mapStateToProps = state => ({
    workspaceId: state.services.workspaceId.data ? state.services.workspaceId.data.id : null
});

export default connect(mapStateToProps)(CyberView);
